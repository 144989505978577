export default {
  setList (state, list) { state.list = list },

  handleUpdate (state, item) {
    const list = [...state.list]
    const index = list.findIndex(o => o.pk === item.pk)
    if (index !== -1) {
      list[index].pk = item.pk
      list[index].number = item.number
      list[index].invoiceNumber = item.invoiceNumber
      list[index].generatedAt = item.generatedAt
      list[index].dispatchAt = item.dispatchAt
      list[index].state = item.state
      list[index].driver = item.driver
      list[index].unit = item.unit
      list[index].plant = item.plant
      list[index].destination = item.destination
    } else {
      list.push({
        pk: item.pk,
        number: item.number,
        invoiceNumber: item.invoiceNumber,
        generatedAt: item.generatedAt,
        dispatchAt: item.dispatchAt,
        state: item.state,
        driver: item.driver,
        unit: item.unit,
        plant: item.plant,
        destination: item.destination,
        stage: {}
      })
    }

    state.list = []
    state.list = list
  },

  handleDelete (state, id) {
    const index = state.list.findIndex(o => o.id === id)
    if (index !== -1) {
      state.list.splice(index, 1)
    }
  },

  updateTimes (state, times) {
    const list = [...state.list]
    const index = list.findIndex(o => o.pk === times.orderId)
    if (index !== -1) {
      list[index].stage = times
    }

    state.list = []
    state.list = list
  }
}