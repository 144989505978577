import session from './session'
import orders from './orders'
import units from './units'
import geofences from './geofences'
import users from './users'
import plants from './plants'
import destinations from './destinations'
import carriers from './carriers'
import drivers from './drivers'

export default {
  session,
  orders,
  units,
  geofences,
  users,
  plants,
  destinations,
  carriers,
  drivers
}