export default {
  set (state, { pk, name, email, level, apiToken, destinationId, destinationsIds }) {
    state.pk = pk
    state.name = name
    state.email = email
    state.level = level
    state.apiToken = apiToken
    state.destinationId = destinationId || []
    state.destinationsIds = destinationsIds || []

    localStorage.setItem('token.token', apiToken)
  },

  clear (state) {
    state.pk = null
    state.name = ''
    state.email = ''
    state.level = ''
    state.apiToken = ''
    state.destinationId = []
    state.destinationsIds = []

    localStorage.removeItem('token.token')
  }
}