import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import es from 'vuetify/es5/locale/es'
import vColors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { es },
    current: 'es'
  },

  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#FD1102',
        secondary: '#FDB801',
        accent: '#FEE401',
        success: vColors.green.darken4,
        warning: vColors.orange.darken4,
        error: vColors.red.darken4
      }
    },

    options: { customProperties: true }
  }
})
