import { apollo } from '@/plugins/apollo'
import gql from 'graphql-tag'
import { i18n } from '@/plugins'
import router from '@/plugins/router'

export default {
  get ({ dispatch, commit }) {
    commit('setLoading', true, { root: true })
    const apiToken = localStorage.getItem('token.token')
    apollo.query({
      query: gql`
        query getSession($apiToken: String!) {
          getSession(apiToken: $apiToken) {
            status
            errors
            result {
              pk
              name
              email
              level
              apiToken

              destinationsIds
              destinationId
            }
          }
        }
      `,
      variables: { apiToken }
    }).then((response) => {
      const { status, result } = response.data.getSession
      if (status === 'OK') {
        commit('set', result)
        dispatch('loadData')
        dispatch('redirect')
      } else {
        localStorage.removeItem('token.token')
        commit('toggleSnackbar', { message: i18n.t('errors.session.expired'), color: 'orange darken-2' }, { root: true })
        router.push({ name: 'login' })
      }
    }).catch((err) => {
      console.log('[vuex.session] Error handled', err)
      commit('toggleSnackbar', null, { root: true })
    }).finally(() => {
      commit('setLoading', false, { root: true })
    })
  },

  loadData ({ commit, dispatch }) {
    commit('setLoading', true, { root: true })

    dispatch('units/getList', true, { root: true })
    dispatch('geofences/getList', true, { root: true })
    dispatch('users/getList', true, { root: true })
    dispatch('orders/getList', true, { root: true })
    dispatch('plants/getList', true, { root: true })
    dispatch('destinations/getList', true, { root: true })
    dispatch('carriers/getList', true, { root: true })
    dispatch('drivers/getList', true, { root: true })

    commit('setLoading', false, { root: true })
  },

  redirect ({ state, commit }) {
    if (router.currentRoute.name === 'login') {
      switch (state.level) {
        case 'ADMIN':
          commit('redirect', { name: 'home' }, { root: true })
          break
        default:
          commit('redirect', { name: 'orders.monitor' }, { root: true })
          break
      }
    }
  }
}