<template>
  <div>
    <v-navigation-drawer app class="elevation-1">
      <v-list-item class="d-flex align-center">
        <img :src="require('@/assets/favicon.svg')" width="30px" height="30px" class="mr-2">
        {{ $t('app.name') }}
      </v-list-item>

      <v-divider class="mx-2" />

      <v-list-group dense>
        <template #activator>
          <v-list-item-content>
            <v-list-item-subtitle>
              {{ session.name }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>

        <v-list-item dense @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-exit-to-app</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('users.logout') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <v-divider class="mx-2" />

      <v-list
        dense
        nav
      >
        <template v-for="link, i in links">
          <v-list-item
            v-if="link.links.length === 0"
            :key="`link-${i}`"
            :to="link.to"
            color="primary"
          >
            <v-list-item-icon>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ link.label }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group
            v-else
            :key="`link-${i}`"
            :value="$route.path.startsWith(link.to)"
          >
            <template #activator>
              <v-list-item-icon>
                <v-icon>{{ link.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ link.label }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="sublink, j in link.links"
              :key="`link-${i}-${j}`"
              :to="sublink.to"
              color="primary"
            >
              <v-list-item-icon>
                <v-icon>{{ sublink.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ sublink.label }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app elevation="0" color="white">
      <v-spacer />

      <template v-if="session.level === 'ADMIN'">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn
              small
              depressed
              outlined
              icon
              class="mx-1"
              color="primary"
              @click="$store.dispatch('import')"
              v-on="on"
            >
              <v-icon small>mdi-table-arrow-left</v-icon>
            </v-btn>
          </template>

          <span>{{ $t('actions.importFromWialon') }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn
              small
              depressed
              outlined
              icon
              class="mx-1"
              color="primary"
              @click="clearMonitor"
              v-on="on"
            >
              <v-icon small>mdi-eraser</v-icon>
            </v-btn>
          </template>

          <span>{{ $t('actions.clearMonitor') }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn
              small
              depressed
              outlined
              icon
              class="mx-1"
              color="primary"
              @click="importDialog = true; report = null"
              v-on="on"
            >
              <v-icon small>mdi-upload-network-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('actions.uploadOrders') }}</span>
        </v-tooltip>
      </template>
    </v-app-bar>

    <v-main>
      <transition name="component-fade" mode="out-in">
        <router-view />
      </transition>
    </v-main>

    <v-footer app class="d-flex justify-center" style="line-height: 14px;" color="primary white--text">
      <p class="pa-0 ma-0 text-center" style="font-size: 14px;">
        {{ $t('copyright.madeWith') }}
        <br>
        <small>{{ $t('copyright.owner') }}</small>
      </p>
    </v-footer>

    <v-dialog
      v-model="importDialog"
      persistent
      width="80%"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          {{ $t('orders.title.load') }}
        </v-card-title>

        <v-card-text>
          <v-file-input
            ref="orderFile"
            v-model="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            :label="$t('orders.report')"
            prepend-icon="mdi-file-document-outline"
            hide-details
            solo
            :disabled="isLoading"
            :loading="isLoading"
            @change="handleFile"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import mqtt from 'mqtt'
export default {
  data () {
    return {
      file: null,
      report: null,
      isLoading: false,
      importDialog: false,
      mqtt: null
    }
  },

  computed: {
    session () { return this.$store.state.session },

    links () {
      if (this.session.level === 'ADMIN') {
        return [
          {
            label: this.$t('pages.home'),
            to: '/Home',
            icon: 'mdi-home-variant-outline',
            links: []
          },
          {
            label: this.$t('pages.reports'),
            to: '/Reports',
            icon: 'mdi-file-excel-outline',
            links: []
          },
          {
            label: this.$t('pages.admins'),
            to: '/Admins',
            icon: 'mdi-account-star-outline',
            links: []
          },
          {
            label: this.$t('pages.orders.title'),
            to: '/Orders',
            icon: 'mdi-receipt',
            links: [
              {
                label: this.$t('pages.orders.monitor'),
                icon: 'mdi-monitor-dashboard',
                to: '/Orders/Monitor'
              },
              {
                label: this.$t('pages.orders.past'),
                icon: 'mdi-clock-outline',
                to: '/Orders/Past'
              }
            ]
          },
          {
            label: this.$t('pages.entities.title'),
            to: '/Entities',
            icon: 'mdi-inbox-multiple-outline',
            links: [
              {
                label: this.$t('pages.entities.geofences'),
                icon: 'mdi-layers',
                to: '/Entities/Geofences'
              },
              {
                label: this.$t('pages.entities.supervisors'),
                icon: 'mdi-account-supervisor-outline',
                to: '/Entities/Supervisors'
              },
              {
                label: this.$t('pages.entities.carriers'),
                icon: 'mdi-account-tie-hat-outline',
                to: '/Entities/Carriers'
              },
              {
                label: this.$t('pages.entities.drivers'),
                icon: 'mdi-steering',
                to: '/Entities/Drivers'
              },
              {
                label: this.$t('pages.entities.units'),
                icon: 'mdi-car-multiple',
                to: '/Entities/Units'
              },
              {
                label: this.$t('pages.entities.customers'),
                icon: 'mdi-account-outline',
                to: '/Entities/Customers'
              },
              {
                label: this.$t('pages.entities.plants'),
                icon: 'mdi-map-marker-down',
                to: '/Entities/Plants'
              },
              {
                label: this.$t('pages.entities.destinations'),
                icon: 'mdi-map-marker-check',
                to: '/Entities/Destinations'
              }
            ]
          }
        ]
      }
      return [
        {
          label: this.$t('pages.orders.title'),
          to: '/Orders/Monitor',
          icon: 'mdi-receipt',
          links: []
        }
      ]
    }
  },

  watch: {
    report (value) {
      if (value) {
        this.performLoad()
      }
    }
  },

  created () {
    const token = localStorage.getItem('token.token')
    if (token) {
      this.$store.dispatch('session/get')
    } else {
      this.$store.commit('redirect', { name: 'login' })
      this.$store.commit('setLoading', false)
    }
  },

  mounted () {
    this.initMqtt()
  },

  methods: {
    initMqtt () {
      this.mqtt = mqtt.connect('mqtts://mqtt.flespi.io', {
        username: process.env.VUE_APP_FLESPI_TOKEN
      })

      this.mqtt.on('connect', (err) => {
        if (!err) {
          console.log('[mqtt] Error connecting')
        } else {
          console.log('[mqtt] Connected')
          console.log('[mqtt] Subscribing to topic: ', process.env.VUE_APP_FLESPI_TOPIC)
          this.mqtt.subscribe(process.env.VUE_APP_FLESPI_TOPIC, (err) => {
            if (!err) {
              console.log('[mqtt] Subscribed')
            } else {
              console.log('[mqtt] Error subscribing', err)
            }
          })
        }
      })

      this.mqtt.on('message', (topic, message) => {
        console.log('[mqtt] Message received')
        message = JSON.parse(message)
        console.log(message)
        const action = message.action
        const mod = message.module

        const payload = message
        delete payload.action
        delete payload.module

        if (mod === 'orders.stage') {
          if (this.session.level === 'ADMIN') {
            this.$store.commit('orders/updateTimes', payload)
          } else {
            const destinations = this.session.destinationsIds || []
            if (this.session.destinationId) {
              destinations.push(this.session.destinationId)
            }

            if (destinations.includes(payload.destination?.pk)) {
              this.$store.commit('orders/updateTimes', payload)
            }
          }
        } else {
          if (this.session.level !== 'ADMIN') { return }
          if (action === 'delete') {
            this.$store.commit(`${mod}/handleDelete`, payload.pk)
          } else {
            this.$store.commit(`${mod}/handleUpdate`, payload)
          }
        }
      })
    },

    isCurrent (page) {
      if ((page?.links || []).length > 0) {
        return this.$route.path.includes(page.to)
      }

      return page.to === this.$route.path
    },

    logout () {
      this.$store.commit('session/clear')
      this.$store.commit('redirect', { name: 'login' })
    },

    async clearMonitor () {
      const operation = await this.$store.dispatch('orders/clear')
      this.isLoading = true

      if (operation) {
        this.$store.commit('toggleSnackbar', {
          message: this.$t('orders.clear.success'),
          icon: 'mdi-check-decagram',
          color: 'green darken-2'
        })
      }
      this.isLoading = false
    },

    async performLoad () {
      const operation = await this.$store.dispatch('orders/load', this.report)
      this.isLoading = true

      if (operation) {
        this.$store.commit('toggleSnackbar', {
          message: this.$t('orders.upload.success'),
          icon: 'mdi-check-decagram',
          color: 'green darken-2'
        })
      }
      this.importDialog = false
      this.report = null
      this.file = null
    },

    async handleFile (file) {
      this.isLoading = true
      if (!file) {
        this.isLoading = false
        return
      }

      const reader = new FileReader()
      await reader.readAsDataURL(file)
      reader.onload = () => {
        const result = reader.result
        if (result.startsWith('data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
          this.report = result
        } else {
          this.file = null
          this.$store.commit('toggleSnackbar', {
            message: this.$t('orders.errors.format.title'),
            caption: this.$t('orders.errors.format.caption'),
            icon: 'mdi-alert',
            color: 'red darken-2'
          })
        }
      }
    }
  }
}
</script>