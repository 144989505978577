/* eslint-disable no-unused-vars */
import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '@/plugins/i18n'
import DefaultLayout from '@/layouts/Default.vue'
import Login from '@/views/Login.vue'
import store from '@/plugins/store'
import session from '../store/session'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/Home'
  },
  {
    path: '/Login',
    name: 'login',
    component: Login
  },
  {
    path: '/',
    component: DefaultLayout,
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem('token.token')) {
        next({ name: 'login' })
      } else {
        next()
      }
    },
    children: [
      {
        path: 'Home',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue')
      },
      {
        path: 'Reports',
        name: 'reports',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Reports.vue')
      },
      {
        path: 'Admins',
        name: 'admins',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Admins.vue')
      }
    ]
  },
  {
    path: '/Orders',
    redirect: '/Orders/Monitor'
  },
  {
    path: '/Orders',
    component: DefaultLayout,
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem('token.token')) {
        next({ name: 'login' })
      } else {
        next()
      }
    },
    children: [
      {
        path: 'Monitor',
        name: 'orders.monitor',
        component: () => import(/* webpackChunkName: "orders" */ '@/views/Orders/Monitor.vue')
      },
      {
        path: 'Past',
        name: 'orders.past',
        component: () => import(/* webpackChunkName: "orders" */ '@/views/Orders/Past.vue')
      }
    ]
  },
  {
    path: '/Entities',
    redirect: '/Entities/Supervisors'
  },
  {
    path: '/Entities',
    component: DefaultLayout,
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem('token.token')) {
        next({ name: 'login' })
      } else {
        next()
      }
    },
    children: [
      {
        path: 'Supervisors',
        name: 'entities.supervisors',
        component: () => import(/* webpackChunkName: "entities" */ '@/views/Entities/Supervisors.vue')
      },
      {
        path: 'Carriers',
        name: 'entities.carriers',
        component: () => import(/* webpackChunkName: "entities" */ '@/views/Entities/Carriers.vue')
      },
      {
        path: 'Drivers',
        name: 'entities.drivers',
        component: () => import(/* webpackChunkName: "entities" */ '@/views/Entities/Drivers.vue')
      },
      {
        path: 'Units',
        name: 'entities.units',
        component: () => import(/* webpackChunkName: "entities" */ '@/views/Entities/Units.vue')
      },
      {
        path: 'Geofences',
        name: 'entities.geofences',
        component: () => import(/* webpackChunkName: "entities" */ '@/views/Entities/Geofences.vue')
      },
      {
        path: 'Customers',
        name: 'entities.customers',
        component: () => import(/* webpackChunkName: "entities" */ '@/views/Entities/Customers.vue')
      },
      {
        path: 'Plants',
        name: 'entities.plants',
        component: () => import(/* webpackChunkName: "entities" */ '@/views/Entities/Plants.vue')
      },
      {
        path: 'Destinations',
        name: 'entities.destinations',
        component: () => import(/* webpackChunkName: "entities" */ '@/views/Entities/Destinations.vue')
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  document.title = `${i18n.t(`pages.${to.name}`)} | ${i18n.t('app.name')}`

  next()
})

export default router
