<template>
  <div>
    <template v-for="(alert, i) in alerts">
      <v-snackbar
        :key="`alert-${alert.id}`"
        v-model="alert.isVisible"
        top
        right
        :timeout="alert.duration"
        color="transparent"
        elevation="0"
        :style="{'margin-top': calculateMargin(i), 'border-radius': '0'}"
        class="snackbar-transition"
        :content-class="`stacked-snackbar ${isVuetify(alert.color) ? alert.color : ''} elevation-2`"
      >
        <div class="d-flex justify-space-between align-center pa-2" :style="isVuetify(alert.color) ? '' : `background-color: ${alert.color}`">
          <v-icon v-if="alert.icon" class="mr-2">{{ alert.icon }}</v-icon>
          
          <h4 :class="`pa-0 ma-0 mr-3 ${validateColor(alert.color || 'primary')}--text`" style="flex: 1; font-weight: 200 !important;">
            {{ alert.message }}
            <template v-if="alert.caption">
              <br>
              <small>{{ alert.caption }}</small>
            </template>
          </h4>

          <v-divider vertical :class="`mr-2 ${validateColor(alert.color || 'primary')}`" style="opacity: 0.5" />

          <v-btn
            x-small
            icon
            class="mx-0"
            :color="validateColor(alert.color || 'primary')"
            @click="removeItem(i)"
          >
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </div>

        <v-progress-linear :value="calculateProgress(now, alert.startAt, alert.duration)" :color="validateColor(alert.color)" />
      </v-snackbar>
    </template>
  </div>
</template>

<script>
import { colors } from '@/mixins'

export default {
  mixins: [colors],

  data () {
    return {
      alerts: [],
      now: (new Date() / 1000),
      seq: 0
    }
  },

  created () {
    this.calculateNow()
    this.$store.subscribe((mutation) => {
      if (mutation.type === 'toggleSnackbar') {
        let payload = mutation.payload

        if (!payload) {
          payload = {
            message: this.$i18n.t('errors.kaput'),
            color: 'red darken-4',
            icon: 'mdi-alert-box-outline',
            duration: 5000
          }
        }

        payload.id = this.seq
        payload.isVisible = false
        
        if (!payload.duration) {
          payload.duration = 5000
        }

        if (!payload.color) {
          payload.color = 'primary'
        }

        if (payload.duration !== -1) {
          payload.startAt = (new Date() / 1000)
        }

        this.seq++
        this.alerts.push(payload)

        setTimeout(() => {
          payload.isVisible = true
        }, 50)

        setTimeout(() => {
          const obj = this.alerts.find(item => item.id === payload.id)

          if (obj) {
            this.alerts.splice(this.alerts.indexOf(obj), 1)
          }
        }, payload.duration + 600)
      }
    })
  },

  methods: {
    calculateNow () {
      this.now = (new Date() / 1000)

      setTimeout(() => {
        this.calculateNow()
      }, 50)
    },

    calculateProgress (now, startAt, duration) {
      if (duration === -1) {
        return 100
      }

      const value = ((now - startAt) / (duration / 1000)) * 100

      if (value >= 100) {
        return 100
      }

      return value
    },

    calculateMargin (i) {
      return `${(this.alerts.length - 1 - i) * 70}px`
    },

    removeItem (i) {
      this.alerts[i].isVisible = false

      setTimeout(() => {
        this.alerts.splice(i, 1)
      }, 300)
    }
  }
}
</script>

<style>
.snackbar-transition {
  transition: all 200ms ease-in-out;
}

.stacked-snackbar {
  padding: 0 !important;
  height: 100%;
  margin: 0;
  margin-right: -10px;
  border-radius: 5px;
}
</style>