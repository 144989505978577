import gql from 'graphql-tag'
import { apollo } from '@/plugins/apollo'

export default {
  getList ({ commit, rootState }, persistLoading) {
    if (!persistLoading) {
      commit('setLoading', true)
    }

    apollo.query({
      query: gql`
        query ($apiToken: String!) {
          units (apiToken: $apiToken) {
            status
            errors
            result {
              pk
              plate
              ident

              carrier {
                name
              }
            }
          }
        }
      `,
      variables: {
        apiToken: rootState.session.apiToken
      }
    }).then((response) => {
      const { status, result } = response.data.units

      if (status === 'OK') {
        commit('setList', result)
      } else {
        commit('toggleSnackbar', null, { root: true })
      }
    }).catch((err) => {
      console.log('[vuex.units] getList error:', err)
      commit('toggleSnackbar', null, { root: true })
    }).finally(() => {
      if (!persistLoading) {
        commit('setLoading', false)
      }
    })
  }
}