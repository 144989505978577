import gql from 'graphql-tag'
import { apollo } from '@/plugins/apollo'
import i18n from '@/plugins/i18n'

export default {
  getList ({ commit, rootState }, persistLoading) {
    if (!persistLoading) {
      commit('setLoading', true)
    }

    apollo.query({
      query: gql`
        query ($apiToken: String!) {
          plants (apiToken: $apiToken) {
            status
            errors
            result {
              pk
              name
              code
              geofence {
                pk
                name
              }
            }
          }
        }
      `,
      variables: {
        apiToken: rootState.session.apiToken
      }
    }).then((response) => {
      const { status, result } = response.data.plants

      if (status === 'OK') {
        commit('setList', result)
      } else {
        commit('toggleSnackbar', null, { root: true })
      }
    }).catch((err) => {
      console.log('[vuex.units] getList error:', err)
      commit('toggleSnackbar', null, { root: true })
    }).finally(() => {
      if (!persistLoading) {
        commit('setLoading', false)
      }
    })
  },

  async assign ({ commit, rootState }, { pk, geofencePk }) {
    try {
      const response = await apollo.mutate({
        mutation: gql`
          mutation ($apiToken: String!, $pk: ID!, $geofencePk: ID!) {
            assignGeofenceToPlant (apiToken: $apiToken, pk: $pk, geofencePk: $geofencePk) {
              status
              errors
            }
          }
        `,
        variables: {
          apiToken: rootState.session.apiToken,
          pk,
          geofencePk
        }
      })
  
      const { status } = response.data.assignGeofenceToPlant

      if (status === 'OK') {
        return true
      } else if (status === 'ACCESSDENIED') {
        commit('toggleSnackbar', {
          message: i18n.t('errors.accessDenied'),
          color: 'red darken-2'
        }, { root: true })
        return false
      } else {
        commit('toggleSnackbar', null, { root: true })
        return false
      }
    } catch (err) {
      console.log('[vuex.plants] assign error:', err)
      commit('toggleSnackbar', null, { root: true })
      return false
    }
  }
}