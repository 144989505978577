<template>
  <v-app>
    <transition name="component-fade" mode="out-in">
      <router-view />
    </transition>

    <global-snackbar />

    <v-overlay
      :value="isLoading"
      opacity="0.7"
    >
      <v-progress-circular indeterminate size="70" color="primary" />
    </v-overlay>
  </v-app>
</template>

<script>
import Snackbar from '@/components/Snackbar.vue'
export default {
  components: {
    'global-snackbar': Snackbar
  },

  computed: {
    isLoading () {
      return this.$store.state.loading
    }
  },

  created () {
    this.$store.subscribe((mutation) => {
      if (mutation.type === 'redirect') {
        this.$router.push(mutation.payload)
      }
    })

    const itemsPerPage = localStorage.getItem('listings.items.per.page')
    if (itemsPerPage) {
      this.$store.commit('setItemsPerPage', parseInt(itemsPerPage))
    } else {
      this.$store.commit('setItemsPerPage', 10)
    }
  }
}
</script>
