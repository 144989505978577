import Vue from 'vue'
import App from './App.vue'
import { router, store, vuetify, i18n, apollo } from '@/plugins'
import { sync } from 'vuex-router-sync'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import '@/assets/app.scss'

Vue.config.productionTip = false

const unsync = sync(store, router)

new Vue({
  vuetify,
  router,
  store,
  i18n,
  apolloProvider: apollo,
  render: h => h(App)
}).$mount('#app')

unsync()