<template>
  <v-container fluid fill-height class="primary">
    <v-row align="center" justify="center">
      <v-col cols="12" md="6">
        <v-card shaped>
          <v-card-title class="d-flex justify-center">
            {{ $t('pages.login') }}
          </v-card-title>

          <v-divider class="mx-4" />

          <v-card-text>
            <v-text-field
              v-model="email"
              :disabled="isLoading"
              :loading="isLoading"
              :label="$t('users.email')"
              data-form-type="email"
              solo
              class="my-2"
              type="email"
              prepend-icon="mdi-account-circle-outline"
              :error="hasErrors('email')"
              :error-messages="getErrors('email')"
              @keydown.enter="handleLogin"
            />

            <v-text-field
              v-model="password"
              :disabled="isLoading"
              :loading="isLoading"
              :label="$t('users.password')"
              data-form-type="password"
              solo
              class="my-2"
              :type="showPassword ? 'text' : 'password'"
              prepend-icon="mdi-shield-key-outline"
              :append-icon="!showPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
              :error="hasErrors('password')"
              :error-messages="getErrors('password')"
              @click:append="showPassword = !showPassword"
              @keydown.enter="handleLogin"
            />
          </v-card-text>

          <v-divider class="mx-4" />

          <v-card-actions class="mx-4">
            <v-btn
              block
              depressed
              :disabled="isLoading"
              :loading="isLoading"
              color="secondary"
              @click="handleLogin"
            >
              {{ $t('users.login') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { orm } from '@/mixins'
import gql from 'graphql-tag'
export default {
  mixins: [orm],
  data () {
    return {
      i: 0,
      email: '',
      password: '',
      errors: {},

      isLoading: false,

      showPassword: false
    }
  },

  mounted () {
    if (process.env.NODE_ENV === 'development') {
      this.email = 'admin@terpel.com'
      this.password = '123456'
    }

    const token = localStorage.getItem('token.token')
    if (token) {
      this.$store.dispatch('session/get')
    } else {
      this.$store.commit('setLoading', false)
    }
  },

  methods: {
    handleLogin () {
      this.isLoading = true
      this.errors = {}
      this.$apollo.mutate({
        mutation: gql`
          mutation login($email: String!, $password: String!) {
            login(email: $email, password: $password) {
              status
              errors
              result {
                pk
                name
                email
                level
                apiToken

                destinationId
                destinationsIds
              }
            }
          }
        `,
        variables: {
          email: this.email,
          password: this.password
        }
      }).then((response) => {
        const { status, errors, result } = response.data.login

        switch (status) {
          case 'OK':
            this.$store.commit('session/set', result)
            this.$store.dispatch('session/loadData')
            this.$store.dispatch('session/redirect')
            break
          case 'BADREQUEST':
            this.errors = errors
            break
          case 'ACCESSDENIED':
            this.$store.commit('toggleSnackbar', { message: this.$t('errors.accessDenied'), color: 'red darken-4' })
            break
          case 'INTERNALERROR':
            this.$store.commit('toggleSnackbar', null)
            break
        }
      }).catch((err) => {
        console.log(err)
        this.$store.commit('toggleSnackbar', null)
      }).finally(() => {
        this.isLoading = false
      })
    }
  }
}
</script>
