export default {
  setList (state, list) { state.list = list },

  handleUpdate (state, item) {
    const list = [...state.list]
    const index = list.findIndex(o => o.pk === item.pk)
    if (index !== -1) {
      list[index] = item
    } else {
      list.push(item)
    }

    state.list = []
    state.list = list
  },

  handleDelete (state, pk) {
    const index = state.list.findIndex(o => o.pk === pk)
    if (index !== -1) {
      state.list.splice(index, 1)
    }
  }
}