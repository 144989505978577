import gql from 'graphql-tag'
import { apollo } from '@/plugins/apollo'

export default {
  getList ({ commit, rootState }, persistLoading) {
    if (!persistLoading) {
      commit('setLoading', true)
    }

    apollo.query({
      query: gql`
        query ($apiToken: String!) {
          users (apiToken: $apiToken) {
            status
            errors
            result {
              pk
              name
              email
              level
            }
          }
        }
      `,
      variables: {
        apiToken: rootState.session.apiToken
      }
    }).then((response) => {
      const { status, result } = response.data.users

      if (status === 'OK') {
        commit('setList', result)
      } else {
        commit('toggleSnackbar', null, { root: true })
      }
    }).catch((err) => {
      console.log('[vuex.units] getList error:', err)
      commit('toggleSnackbar', null, { root: true })
    }).finally(() => {
      if (!persistLoading) {
        commit('setLoading', false)
      }
    })
  },

  async details ({ commit, rootState }, pk) {
    try {
      const response = await apollo.query({
        query: gql`
          query ($apiToken: String!, $pk: ID) {
            users (apiToken: $apiToken, pk: $pk) {
              status
              errors
              result {
                pk
                name
                email
                level
                destinationsIds
                destinationId
              }
            }
          }
        `,
        variables: {
          apiToken: rootState.session.apiToken,
          pk
        }
      })

      const { status, result } = response.data.users

      if (status === 'OK' && result.length >= 1) {
        return result[0]
      } else {
        commit('toggleSnackbar', null, { root: true })
        return null
      }
    } catch (err) {
      console.log('[vuex.users] details error:', err)
      commit('toggleSnackbar', null, { root: true })
      return null
    }
  },

  async add ({ commit, rootState }, data) {
    try {
      const response = await apollo.mutate({
        mutation: gql`
          mutation ($apiToken: String!, $data: UserInput!) {
            addUser(apiToken: $apiToken, data: $data) {
              status
              errors
              result {
                pk
                name
                email
                level

                destinationsIds
                destinationId
              }
            }
          }
        `,
        variables: {
          apiToken: rootState.session.apiToken,
          data
        }
      })

      const { status, errors, result } = response.data.addUser
      
      switch (status) {
        case 'OK':
          return { status, result }
        case 'BADREQUEST':
          return { status, errors }
        default:
          commit('toggleSnackbar', null, { root: true })
          return { status: null, errors: null, result: null }
      }
    } catch (err) {
      console.log('[vuex.users] add error:', err)
      commit('toggleSnackbar', null, { root: true })
      return { status: null, errors: null, result: null }
    }
  },

  async edit ({ commit, rootState }, data) {
    try {
      const response = await apollo.mutate({
        mutation: gql`
          mutation ($apiToken: String!, $data: UserInput!) {
            editUser(apiToken: $apiToken, data: $data) {
              status
              errors
              result {
                pk
                name
                email
                level

                destinationsIds
                destinationId
              }
            }
          }
        `,
        variables: {
          apiToken: rootState.session.apiToken,
          data
        }
      })

      const { status, errors, result } = response.data.editUser
      
      switch (status) {
        case 'OK':
          return { status, result }
        case 'BADREQUEST':
          return { status, errors }
        default:
          commit('toggleSnackbar', null, { root: true })
          return { status: null, errors: null, result: null }
      }
    } catch (err) {
      console.log('[vuex.users] edit error:', err)
      commit('toggleSnackbar', null, { root: true })
      return { status: null, errors: null, result: null }
    }
  },

  async delete ({ commit, rootState }, pk) {
    try {
      const response = await apollo.mutate({
        mutation: gql`
          mutation ($apiToken: String!, $pk: ID!) {
            deleteUser(apiToken: $apiToken, pk: $pk) {
              status
            }
          }
        `,
        variables: {
          apiToken: rootState.session.apiToken,
          pk
        }
      })

      const { status } = response.data.deleteUser
      
      switch (status) {
        case 'OK':
          return true
        default:
          commit('toggleSnackbar', null, { root: true })
          return false
      }
    } catch (err) {
      console.log('[vuex.users] delete error:', err)
      commit('toggleSnackbar', null, { root: true })
      return false
    }
  }
}