import gql from 'graphql-tag'
import { apollo } from '@/plugins/apollo'
import i18n from '@/plugins/i18n'

export default {
  import ({ commit, state }) {
    commit('setLoading', true)
    apollo.mutate({
      mutation: gql`
        mutation ($apiToken: String!) {
          importFromWialon (apiToken: $apiToken) {
            status
          }
        }
      `,
      variables: {
        apiToken: state.session.apiToken
      }
    }).then((response) => {
      const { status } = response.data.importFromWialon

      if (status === 'OK') {
        commit('toggleSnackbar', {
          message: i18n.t('wialon.import.success.title'),
          caption: i18n.t('wialon.import.success.caption'),
          color: 'green darken-2'
        })
      } else if (status === 'WIALONERROR') {
        commit('toggleSnackbar', {
          message: i18n.t('wialon.import.error.title'),
          caption: i18n.t('wialon.import.error.caption'),
          color: 'red darken-2'
        })
      } else {
        commit('toggleSnackbar', null)
      }
    }).catch((err) => {
      console.log('[vuex] importFromWialon error:', err)
    }).finally(() => {
      commit('setLoading', false)
    })
  }
}