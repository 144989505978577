import { apollo } from '@/plugins/apollo'
import gql from 'graphql-tag'
import i18n from '@/plugins/i18n'

export default {
  getList ({ commit, rootState }, persistLoading) {
    if (!persistLoading) {
      commit('setLoading', true)
    }

    apollo.query({
      query: gql`
        query ($apiToken: String!) {
          orders (apiToken: $apiToken) {
            status
            errors
            result {
              pk
              number
              invoiceNumber
              generatedAt
              dispatchAt
              state

              stage {
                loading {
                  startAt
                  endAt
                }
                transit {
                  startAt
                  endAt
                }
                delivering {
                  startAt
                  endAt
                }
                startAt
                endAt
              }

              driver {
                pk
                name
                code
                phone
              }
              unit {
                pk
                plate
                ident
                carrier {
                  pk
                  name
                }
              }
              plant {
                pk
                name
                code
                geofence {
                  pk
                  name
                  mode
                  shape
                }
              }
              destination {
                pk
                name
                code
                owner {
                  pk
                  name
                  code
                }
                geofence {
                  pk
                  name
                  mode
                  shape
                }
              }
            }
          }
        }
      `,
      variables: {
        apiToken: rootState.session.apiToken
      }
    }).then((response) => {
      const { status, result } = response.data.orders

      if (status === 'OK') {
        commit('setList', result)
      } else {
        commit('toggleSnackbar', null, { root: true })
      }
    }).catch((err) => {
      console.log('[vuex.units] getList error:', err)
      commit('toggleSnackbar', null, { root: true })
    }).finally(() => {
      if (!persistLoading) {
        commit('setLoading', false)
      }
    })
  },

  async load ({ commit, rootState }, file) {
    try {
      const response = await apollo.mutate({
        mutation: gql`
          mutation ($apiToken: String!, $base64Report: String!) {
            loadOrdersFromReport(
              apiToken: $apiToken,
              base64Report: $base64Report
            ) {
              status
            }
          }
        `,
        variables: {
          apiToken: rootState.session.apiToken,
          base64Report: file
        }
      })

      const { status } = response.data.loadOrdersFromReport

      if (status === 'OK') {
        return true
      } else if (status === 'ACCESSDENIED') {
        commit('toggleSnackbar', { message: i18n.t('errors.accessDenied'), color: 'red darken-2' }, { root: true })
        return false
      } else {
        commit('toggleSnackbar', null, { root: true })
        return false
      }
    } catch (err) {
      console.log('[vuex.orders] load error handled: ', err)
      commit('toggleSnackbar', null, { root: true })
      return false
    }
  },

  async clear ({ commit, rootState }, pk) {
    try {
      const response = await apollo.mutate({
        mutation: gql`
          mutation ($apiToken: String!) {
            cancelOrders(apiToken: $apiToken) {
              status
            }
          }
        `,
        variables: {
          apiToken: rootState.session.apiToken,
          ordersIds: pk ? [pk] : []
        }
      })

      const { status } = response.data.cancelOrders

      if (status === 'OK') {
        return true
      } else if (status === 'ACCESSDENIED') {
        commit('toggleSnackbar', { message: i18n.t('errors.accessDenied'), color: 'red darken-2' }, { root: true })
        return false
      } else {
        commit('toggleSnackbar', null, { root: true })
        return false
      }
    } catch (err) {
      console.log('[vuex.orders] load error handled: ', err)
      commit('toggleSnackbar', null, { root: true })
      return false
    }
  },

  async getMessages ({ commit, rootState }, { unitId, startAt, endAt }) {
    try {
      const response = await apollo.query({
        query: gql`
          query ($apiToken: String!, $unitId: ID!, $startAt: Unix!, $endAt: Unix!) {
            messages(apiToken: $apiToken, unitId: $unitId, startAt: $startAt, endAt: $endAt) {
              status
              result {
                latitude
                longitude
                direction
                generatedAt
              }
            }
          }
        `,
        variables: {
          apiToken: rootState.session.apiToken,
          unitId,
          startAt,
          endAt
        }
      })

      const { status, result } = response.data.messages

      if (status === 'OK') {
        return result
      } else if (status === 'ACCESSDENIED') {
        commit('toggleSnackbar', { message: i18n.t('errors.accessDenied'), color: 'red darken-2' }, { root: true })
        return []
      } else {
        commit('toggleSnackbar', null, { root: true })
        return []
      }
    } catch (err) {
      console.log('[vuex.orders] load error handled: ', err)
      commit('toggleSnackbar', null, { root: true })
      return []
    }
  }
}